import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import {Deutsch, English, Type, LinkWORK, LinkDE, LinkEN, Page, SiteTitle} from '../components/linksetc.js'
import Typist from 'react-typist'

const QuotesPage = ( {location} ) => (




  <Layout>


    <SiteTitle string="Quotes" />


      <Page location={location}>


         <Deutsch Language={location.search}>
              <Type>
                "Könnt ihr das auch noch verwursteln?"
                <br/>
                - diese Marketing-Kundin hatte keine Illusionen über unsere Arbeit

                <p/>
                "es muss fertig werden. koste es was es wolle!"
                <br/>
                - meine Lieblingskundin

                
                <p/>
                "Allerdings können wir vereinbaren, dass wenn du den Auftrag <u>nicht</u> bekommst wir dir deinen Aufwand vergüten."
                <br/>
                - absurder Kuhandel aber ok

                <p/>
                "Klingt ein bisschen wie nordkoreanische Propaganda"
                <br/>
                - zu ehrliches Feedback von meiner Seite


                <p/>
                "We come back to you after a long time but we’re still really interested by your “copywriting”."
                <br/>
                - well I am still "interested"


                <p/>
                "Das sprengt jetzt unser bisher gekanntes Niveau um ein Vielfaches."
                <br/>
                - preissensitiver Kunde reagiert auf Rechnung


                <p/>
                "If we like it we’re gonna pay"
                <br/>
                - ja so mach ich's auch immer


                <p/>
                "oh ich klinge ja plötzlich so intelligent"
                <br/>
                - zufriedene Ghostwriting Kundin


                <p/>
                "What is this ecofascist trash doing on my timeline"
                <br/>
                - a social media users (very understandable) reaction to my greenwashing campaign

                <p/>
                "Kevin es tut mir leid dass ich dich mit dem Scheiss belästigen muss aber es gibt sehr viel Geld."
                <br/>
                - ein Kunde, der mich leider zu gut kennt

                  <p/>
                "Kevin das ist genial!!! Aber... das ist viel zu gut für den Kunden! Das behalten wir für uns, ha!"
                <br/>
                - auch ein Lieblingskunde

              </Type>
        </Deutsch>



      </Page>

  </Layout>


)

export default QuotesPage
